@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');
:root{
    --main-color: #FFFFFF;
    --primary-yellow: #F9AE3B;
    --primary-grey: #F8F9FA;
    --logo-font: "Roboto Mono";
    --text-font: "Roboto";
    --text-weight: 400;
}
body,html{
    overflow-x: hidden;
}
.app{
    overflow-y: hidden;
    padding: 3em 5em;
    height: 130vh;
    background-color: var(--primary-grey); 
    .navbar .logo{
        display: flex;
        width: 100%;
        float: left;
        align-items: center;
        img{
            width: 3em;
            display: inline;
        }
        #logo-text{
            font-family: var(--logo-font);
            font-weight: 700;
            font-size: 24px;
            margin-left: 1em;
        }
    }
    .hero{
        z-index: 1;
        position: relative;
        margin-top: 10em;
        font-family: var(--text-font);
        font-weight: 700;
        #protocol-countdown-title{
            font-weight: 700;
            font-size: 3em;
        }
        #protocol-countdown-time{
            margin: 1em 0;
            font-size: 2em;
        }
        #doc-link{
            margin-top: 1em;
            font-weight: 700;
            font-size: 1.3em;
            a{
                color: black;
            }
        }
        #doc-link a:hover{
            color: var(--primary-yellow);
            
        }
        .social-btn-container{
            margin-top: 1em;
            display: flex;
            width: 70% !important;
            place-content: space-between;
            img{
                width: 2em;
            }
            img:hover{
                filter: invert(76%) sepia(94%) saturate(851%) hue-rotate(326deg) brightness(97%) contrast(101%);
            }
        }
        #longmon{
            z-index: 1;
            width: 25em;
            position: absolute;
            left: 20%;
        }
        #cloud1{
            display: none;
            z-index: 0;
            position: absolute;
            width: 10em;
            left:10%;
            top: 90%;
        }
        #cloud2{
            display: none;
            position: absolute;
            left: 70%;
            top: 200%;
        }
    }
}

@media only screen and (min-width:520px){
    .app{
        overflow-y: hidden;
        padding: 3em 5em;
        height: 98vh;
        background-color: var(--primary-grey); 
        .navbar .logo{
            display: flex;
            width: 100%;
            float: left;
            align-items: center;
            img{
                width: 3em;
                display: inline;
            }
            #logo-text{
                font-family: var(--logo-font);
                font-weight: 700;
                font-size: 24px;
                margin-left: 1em;
            }
        }
        .hero{
            position: relative;
            margin-top: 10em;
            font-family: var(--text-font);
            font-weight: 700;
            #protocol-countdown-title{
                font-weight: 700;
                font-size: 3em;
            }
            #protocol-countdown-time{
                font-size: 2em;
            }
            #doc-link{
                margin-top: 1em;
                font-weight: 700;
                font-size: 1.3em;
                a{
                    color: black;
                }
            }
            #doc-link a:hover{
                color: var(--primary-yellow);
                
            }
            .social-btn-container{
                margin-top: 1em;
                display: flex;
                width: 20% !important;
                place-content: space-between;
                img{
                    width: 2em;
                }
                img:hover{
                    filter: invert(76%) sepia(94%) saturate(851%) hue-rotate(326deg) brightness(97%) contrast(101%);
                }
            }
            #longmon{
                z-index: 1;
                width: 25em;
                position: absolute;
                left: 50%;
            }
            #cloud1{
                display: block;
                z-index: 0;
                position: absolute;
                width: 20em;
                left:40%;
                top: 60%;
            }
            #cloud2{
                display: block;
                z-index: 2;
                position: absolute;
                left: 70%;
                top: 200%;
            }
        }
    }
  }
  
  @media only screen and (min-width:726px){
    .app{
      padding: 3em 8em;
    }
  }
  
  
  @media only screen and (min-width:1200px){
    .app{
      padding: 3em 10em;
    }
  }
  
  @media only screen and (min-width:1600px){
      .app{
        padding: 5em 20em;
      }
  }